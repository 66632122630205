/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import HomeSlider from "./homeSlider";
import Layout from "../components/layout";
import { gameDataInstance } from "../config/axiosConfig";
import { useUserContext } from "../context/userContext";

function Home() {
  const [gamePlayed, setGamePlayed] = useState([]);
  const { userLoggedIn } = useUserContext();

  const requiredGames = ["Alphabets", "Numbers", "Colours", "Family"];
  useEffect(() => {
    const checkGamesPlayed = async () => {
      try {
        const response = await gameDataInstance().get(`/get-certificate`);
        setGamePlayed(response?.data.games);
        if (response?.data.games.length >= requiredGames.length) {
        } else {
          setGamePlayed(response?.data?.games);

        }
      } catch (error) {
        console.error("Error fetching games played:", error);
      }
    };
    checkGamesPlayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  return (
    <Layout>
      <>
        <div className="side-info">
          <h2 className="head-name">
            Mandaean <span>Kids Zone</span>
          </h2>
        </div>
        <div className="content">
          <HomeSlider games={gamePlayed} />
          <div className="info-cover">
            <div className="d-flex justify-content-center">
              <div className="right-assets">
                <img src="/Images/cloud-1.svg" className="cloud-right" alt="" />
                <img
                  src="/Images/orange-ball.svg"
                  className="ball-right"
                  alt=""
                />
              </div>
              <div className="main-asset">
                <img src="/Images/cat.png" className="cat-img" alt="" />
              </div>
              <div className="left-assets">
                <img
                  src="/Images/blue-ball.svg"
                  className="blue-ball-left"
                  alt=""
                />
                <img src="/Images/cloud-2.svg" className="cloud-left" alt="" />
                <img
                  src="/Images/red-ball.svg"
                  className="red-ball-left"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
}

export default Home;
