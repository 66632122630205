import React ,{useState, useEffect} from "react";

import { useNavigate } from "react-router-dom";
import Layout from "../components/layout";

function LearnFamily() {
  const navigate = useNavigate();
  const [token, setToken] = useState()
  useEffect(()=>{
    const token = localStorage.getItem('token')
    setToken(token)
  },[])
  const handleBack = () => {
    navigate("/");
  };
  return (
    <Layout>
      <div className="side-info">
        <button className="back-button" onClick={handleBack}>
          <img src="/Images/back-button.svg" alt="back" /> Back
        </button>
      </div>

      <div className="game-wrapper">
        <div class="iframe-container">
          <iframe
            title="Game"
            src={`https://mandean-family.netlify.app/?token=${token}`}
          ></iframe>
        </div>
      </div>
    </Layout>
  );
}

export default LearnFamily;
