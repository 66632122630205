import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Register from "../Auth/register";
import LogIn from "../Auth/login";
import { Link } from "react-router-dom";
import html2pdf from "html2pdf.js";
import Certificate from "./certificate";
import { useRef } from "react";
import { gameDataInstance } from "../config/axiosConfig";
import { toast } from "react-toastify";
import CertificatePending from "./certificatePending";
import { useUserContext } from "../context/userContext";

const Header = () => {
  const {
    userLoggedIn,
    setUserLoggedIn,
    showLogin,
    setShowLogin,
    showRegister,
    setShowRegister,
  } = useUserContext();

  const [showGamePending, setShowGamesPending] = useState(false);
  const [studentName, setStudentName] = useState();
  const [completionDate, setCompletionDate] = useState();
  const [gamePlayed, setGamePlayed] = useState([]);

  
  const handleLogout = () => {
    localStorage.removeItem("token");
    setUserLoggedIn(false);

    toast.success("Successfully logged out", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  const componentRef = useRef();

  const handleDownloadPdf = () => {
    if (gamePlayed.length >= requiredGames.length) {
      const element = componentRef.current;
      const options = {
        margin: 0, // Minimize the margins to avoid cutting
        filename: "certificate.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: [10, 6.25], orientation: "landscape" }, // A4 in landscape
      };
      html2pdf().set(options).from(element).save();
    } else {
      setShowGamesPending(true);
      // toast.error("Please complete all games.", { toastId: "download-certi" });
    }
  };

  //----check for certificate
  const requiredGames = ["Alphabets", "Numbers", "Colours", "Family"];
  useEffect(() => {
    const checkGamesPlayed = async () => {
      try {
        const response = await gameDataInstance().get(`/get-certificate`);
        setGamePlayed(response?.data.games);
        if (response?.data.games.length >= requiredGames.length) {
          // setShowCertificate(true);
          setStudentName(response?.data.userName);
          const dateObject = new Date(response?.data.lastGameTime);
          const options = { year: "numeric", month: "long", day: "numeric" };
          const formattedDate = dateObject.toLocaleDateString("en-US", options);
          setCompletionDate(formattedDate);
        } else {
          setGamePlayed(response?.data?.games);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
              console.log("Unauthorized: Token may have expired", error.response);
              localStorage.removeItem("token");
              setUserLoggedIn(false)
          } else {
              console.error("Error fetching games played:", error.response.data);
          }
        } else if (error.request) {
            console.error("No response received:", error.request);
        } else {
            console.error("Error setting up request:", error.message);
        }
      }
    };
    checkGamesPlayed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);



  return (
    <div className="header">
      <div style={{ display: "none" }}>
        <div
          ref={componentRef}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Certificate
            studentName={studentName}
            completionDate={completionDate}
          />
        </div>
      </div>
      <Link to={"/"}>
        <div className="logo-container">
          <img src="/Images/logo.png" alt="Logo" className="logo" />
        </div>
      </Link>
      <div className="header-buttons header-phone-view">
        {userLoggedIn ? (
          <>
            <Link to={"/leader-board"} className="leader-board-link">
              Leaderboard
            </Link>

            <div className="header-buttons">
              <button
                className="register-button certi-button"
                onClick={handleDownloadPdf}
              >
                <DownloadIcon />
                Certificate
              </button>
              <button className="login-button" onClick={() => handleLogout()}>
                Logout
              </button>
            </div>
          </>
        ) : (
          <>
            <button
              className="login-button"
              onClick={() => setShowLogin(!showLogin)}
            >
              Login
            </button>
            <button
              className="register-button"
              onClick={() => setShowRegister(!showRegister)}
            >
              Register
            </button>
          </>
        )}
      </div>
      <Modal
        size="md"
        show={showRegister}
        onHide={() => setShowRegister(!showRegister)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Register setShowRegister={setShowRegister} />
        </Modal.Body>
      </Modal>
      <Modal
        size="md"
        show={showLogin}
        onHide={() => setShowLogin(!showLogin)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <LogIn
            setShowLogin={setShowLogin}
            setShowRegister={setShowRegister}
          />
        </Modal.Body>
      </Modal>

      <Modal
        size="md"
        show={showGamePending}
        onHide={() => setShowGamesPending(false)}
        centered
        className="auth-popup"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <CertificatePending
            gamesResponse={gamePlayed}
            setShowGamesPending={setShowGamesPending}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;

export const DownloadIcon = () => {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
        fill="#fff"
      />
      <path
        d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
        fill="#ffff"
      />
    </svg>
  );
};
